import { addDoc, collection, query, getDocs, where, updateDoc, doc, deleteDoc } from "firebase/firestore";
import {db} from "./firebase";

export async function GetEnhancedBookingInfo(id){
    const enhancedBookingDataRef = collection(db, "enhancedBooking");
    const q = await query(enhancedBookingDataRef, where('EnhancedKey', '==', id))
    const querySnapshot = await getDocs(q);
    var enhancedBookingDataArray = []
    
    querySnapshot.forEach((doc) => {
        var enhancedDataItem = { id: doc.id, ...doc.data() }
        enhancedBookingDataArray.push(enhancedDataItem)
      });
      return enhancedBookingDataArray;
}

export async function AddEnhancedBookingRef(data){
    console.log(data)
    await addDoc(collection(db, "enhancedBooking"), data);
    const bookedDatesRef = collection(db, "bookedDates");
    const q = await query(bookedDatesRef, where('BookingId', '==', data.EnhancedKey))
    const querySnapshot = await getDocs(q);
    var bookedDatesArray = []
    
    querySnapshot.forEach((doc) => {
        var bookedDataItem = { id: doc.id, ...doc.data() }
        bookedDatesArray.push(bookedDataItem)
      });
      console.log(bookedDatesArray)
    var bookedDatesId = bookedDatesArray[0].id
    const docRef = doc(db,"bookedDates", bookedDatesId);
    await updateDoc(docRef,{
      Booked: data.resultOfCC
    })
}