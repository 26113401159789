// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCfhoEhBx6RzLv0IkCaDXsJljEnjI7tOPI",
  authDomain: "finessephotos-3069d.firebaseapp.com",
  databaseURL: "https://finessephotos-3069d.firebaseio.com",
  projectId: "finessephotos-3069d",
  storageBucket: "finessephotos-3069d.firebasestorage.app",
  messagingSenderId: "167931733607",
  appId: "1:167931733607:web:dd22cfe5fffdb9a642d6c2",

};

const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app)
export {
  db,
  auth,
  logInWithEmailAndPassword,
  storage
};