import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { postData } from '../scripts/PostData';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../services/firebase';
import { AddAlbum } from '../services/albums-service';

export default class AlbumUploader extends Component {
    constructor(props) {
        super(props);
        this.onAlbumFormSubmit = this.onAlbumFormSubmit.bind(this);
        this.state = {
            albumName: "",
            blogText: "",
            displayAlert: false,
            file: null,
            progress: 0,
            imageUrl: ''
        }
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    async onAlbumFormSubmit() {
        var data = {
            AlbumName: this.state.albumName,
            AlbumImageUrl: this.state.imageUrl,
            BlogText: this.state.blogText,
            Status: true
        }
        await AddAlbum(data).then(() => { this.props.action() });
        this.clearForm()
    }

    clearForm() {
        this.setState({ albumName: "", blogText: "", imageUrl: "", progress:0, file:null }, this.displaySuccessMessage)
    }

    displaySuccessMessage() {
        this.setState({ displayAlert: true })
    }

    displaySuccessAlert() {
        return (
            <div>
                <Alert color="success">
                    Album was successfully created!
                </Alert>
            </div>
        )
    }


    handleChange = (e) => {
        e.preventDefault()
        if (e.target.files[0]) {
            this.setState({ file: e.target.files[0] });
        }
    };

    handleUpload = (e) => {
        e.preventDefault()
        const { file } = this.state;

        if (!file) return;

        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.setState({ progress });
            },
            (error) => {
                console.error('Error uploading file:', error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    this.setState({ imageUrl: downloadURL });
                });
            }
        );
    };


    render() {


        return (
            <Row>
                <Col>
                    {this.state.displayAlert ? (
                        this.displaySuccessAlert()
                    ) : null}
                    <Form onSubmit={this.onAlbumFormSubmit}>
                        <FormGroup row>
                            <Label for="albumName" sm={2}>Name Of Album</Label>
                            <Col sm={10}>
                                <Input type="text" name="albumName" id="albumName" placeholder="Enter Text..." value={this.state.albumName} onChange={e => this.setState({ albumName: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="blogText" sm={2}>Album Blog Text</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="blogText" id="blogText" placeholder="Enter Text..." value={this.state.blogText} onChange={e => this.setState({ blogText: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Upload a cover image for album </Label>
                            <Col>
                                <Col>
                                    <div style={{ backgroundColor: '#57a9c8', color: 'white', padding: 10, borderRadius: 4, pointer: 'cursor', float:"left"}}>
                                        <input type="file" onChange={this.handleChange} />
                                        <button onClick={(e) => this.handleUpload(e)}>Upload</button>
                                        <progress style={{marginLeft:10}} value={this.state.progress} max="100" />
                                        {this.state.imageUrl && <img src={this.state.imageUrl} style={{maxHeight:200, maxWidth:200}} alt="Uploaded file" />}
                                    </div>
                                </Col>
                            </Col>
                        </FormGroup>
                        <Button type="button" style={{ marginLeft: 10 }} onClick={this.onAlbumFormSubmit}>Submit</Button>
                    </Form>
                </Col>
            </Row>


        )
    }
}