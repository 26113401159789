import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import {postData} from '../scripts/PostData';
import { DeleteAlbum, UpdateAlbumStatus } from '../services/albums-service';


export default class AlbumTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonialName: "",
            testimonialText: "",
            testimonials: [],
            apiInfo: [],
        }
    }

    componentWillMount(){
        this.setState({ apiInfo: this.props.apiInfo });
      }

    componentWillReceiveProps(nextProps) {
        this.setState({ apiInfo: nextProps.apiInfo });
    }

    isActive = (status) => {
        if (status) {
            return "Active"
        }
        else {
            return "Inactive"
        }
    };

    GetButtonText(val) {
        if (val) {
            return "Set To Inactive"
        }
        else {
            return "Set To Active"
        }
    }
    async DeleteAlbumAndContents(id, folderCoverImageUrl){
        if (window.confirm("Deleting the album will delete all included images. Do you want to proceed?")) {
           await DeleteAlbum(id, folderCoverImageUrl).then(() => {
            this.props.action()
           });
        } else {
          return;
        }
    }

    render() {
        return (
            <Row>
                <Col>
                    <table id="albumsTable" style={{ fontSize: 15 }}>
                        <thead>
                            <tr>
                                <th>Album Name</th>
                                <th>Blog Text</th>
                                <th>Album Image</th>
                                <th>Status</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.apiInfo.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{data.AlbumName}</td>
                                        <td>{data.BlogText}</td>
                                        <td><img
                                            style={{ maxHeight: 100 }}
                                            src={data.AlbumImageUrl}
                                            alt="AlbumImage"
                                        /></td>
                                        <td>{this.isActive(data.Status)}</td>
                                        <td><Button onClick={() => {this.DeleteAlbumAndContents(data.id, data.AlbumImageUrl)}}>Delete</Button> <br /> <br />
                                            <Button onClick={() => {UpdateAlbumStatus(data.Status, data.id); this.props.action();}} value={this.GetButtonText(data.Status)}>{this.GetButtonText(data.Status)}</Button> </td>
                                    </tr>
                                )
                            })}
                        </tbody>


                    </table>
                </Col>
            </Row>
        )
    }
}