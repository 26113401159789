import React, { Component } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { postData } from '../scripts/PostData.js';
import { SketchPicker } from 'react-color';
import { AddPromotion, DeletePromotion, UpdatePromotionStatus } from '../services/promotions-service.js';
export default class PromotionTable extends Component {
    constructor(props) {
        super(props);
        this.SetBackgroundAndFontColour = this.SetBackgroundAndFontColour.bind(this);
        this.state = {
            apiInfo: [],
            editRow: "",
            promotionName:"",
            promotionDetails:"",
            background: "#000",
            fontColor: "#fff",
        }
    }

    componentWillMount() {
        this.setState({ apiInfo: this.props.apiInfo });
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ apiInfo: nextProps.apiInfo });
    }

    DeleteDocument = async (id) => {
        await DeletePromotion(id).then(() => {
            this.props.action()
        })
    };

    isActive = (status) => {
        if (status) {
            return "Active"
        }
        else {
            return "Inactive"
        }
    };

    GetButtonText(val) {
        console.log(val)
        if (val) {
            return "Set To Inactive"
        }
        else {
            return "Set To Active"
        }
    }

    async SetStatus(id, status) {
            await UpdatePromotionStatus(id, !status).then(() => {this.props.action()})
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    handleFontChangeComplete = (color) => {
        this.setState({ fontColor: color.hex });
    };
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleFontClick = () => {
        this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker })
    };
    handleFontClose = () => {
        this.setState({ displayFontColorPicker: false })
    };

    SetRowToEdit(rowKey) {
        this.setState({ editRow: rowKey })
    }

    submitChanges(key, promotionNameDB, promotionDetailsDB, promotionStatus){
      
        var promotionName = this.state.promotionName;
        var promotionDetails = this.state.promotionDetails;
        var promotionFontColour = this.state.fontColor;
        var promotionBackgroundColour = this.state.background;


        if(promotionName.length === 0)
        {
            promotionName = promotionNameDB
        }
        if(promotionDetails.length === 0){
            promotionDetails = promotionDetailsDB
        }

        this.submitChangesToDb(key,promotionName, promotionDetails, promotionFontColour, promotionBackgroundColour,promotionStatus, "edit")
        this.setState({editRow:""})
    }

    submitChangesToDb(rowKey,promotionName,promotionDetails, promotionFontColour, promotionBackgroundColour,promotionStatus,updateType){
        
        var data = {
          Key:rowKey,
          Name:promotionName,
          PromoText:promotionDetails,
          BackgroundColor:promotionBackgroundColour,
          FontColor:promotionFontColour,
          Status:promotionStatus,
          UpdateType:updateType
        }
        AddPromotion(data).then((response) => {this.props.action()});;
}

    SetBackgroundAndFontColour(bcColour, fColour){
        this.setState({background:bcColour, fontColor:fColour});
    }
    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <Row>
                <Col>
                    <table id="promotionTable" style={{ fontSize: 15 }}>
                        <thead>
                            <tr>
                                <th>Promotion Name</th>
                                <th>Promotion Text</th>
                                <th>Promotion Bakground Colour</th>
                                <th>Promotion Font Colour</th>
                                <th>Promotion Active</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.apiInfo.map((data, i) => {
                                if (this.state.editRow !== data.id) {
                                    return (
                                        <tr key={data.id}>
                                            <td>{data.Name}</td>
                                            <td>{data.PromoText}</td>
                                            <td><div>{data.BackgroundColor}</div> <div className="square" style={{ borderStyle: "ridge", height: 50, width: 50, backgroundColor: data.BackgroundColor, marginLeft: "auto", marginRight: "auto" }}></div></td>
                                            <td>{data.FontColor}<div className="square" style={{ borderStyle: "ridge", height: 50, width: 50, backgroundColor: data.FontColor, marginLeft: "auto", marginRight: "auto" }}></div></td>
                                            <td>{this.isActive(data.Active)}</td>
                                            <td><Button onClick={(event) => { this.DeleteDocument(data.id) }}>Delete</Button> <br /> <br />
                                                <Button style={{ marginRight: 10 }} onClick={(event) => {this.SetRowToEdit(data.id); this.SetBackgroundAndFontColour(data.BackgroundColour,data.FontColour)}}>Edit</Button>
                                                <Button onClick={(event) => this.SetStatus(data.id, data.Active)} value={this.GetButtonText(data.Active)}>{this.GetButtonText(data.Active)}</Button></td>
                                        </tr>
                                    )
                                }
                                else {
                                    return (
                                        <tr key={data.id}>
                                            <td><Input type="text" name="promoName" id="promoName" defaultValue={data.Name} onChange={e => this.setState({ promotionName: e.target.value })} /></td>
                                            <td><Input type="text" name="promoName" id="promoName" defaultValue={data.PromoText} onChange={e => this.setState({ promotionDetails: e.target.value })} /></td>
                                            <td><Button onClick={this.handleClick} style={{ marginLeft: 10 }}>Pick Background Color</Button>
                                                {this.state.displayColorPicker ? <div style={popover}>
                                                    <div style={cover} onClick={this.handleClose} />
                                                    <SketchPicker
                                                        color={this.state.background}
                                                        onChangeComplete={this.handleChangeComplete}
                                                    />
                                                </div> : null}<div>{this.state.background}</div> <div className="square" style={{ borderStyle: "ridge", height: 50, width: 50, backgroundColor: this.state.background, marginLeft: "auto", marginRight: "auto" }}></div></td>
                                            <td>       <Button onClick={this.handleFontClick} style={{ marginLeft: 10 }}>Pick Font Color</Button>
                                                {this.state.displayFontColorPicker ? <div style={popover}>
                                                    <div style={cover} onClick={this.handleFontClose} />
                                                    <SketchPicker
                                                        color={this.state.fontColor}
                                                        onChangeComplete={this.handleFontChangeComplete}
                                                    />
                                                </div> : null}<div>{this.state.fontColor}</div><div className="square" style={{ borderStyle: "ridge", height: 50, width: 50, backgroundColor: this.state.fontColor, marginLeft: "auto", marginRight: "auto" }}></div></td>
                                            <td>{this.isActive(data.promotionStatus)}</td>
                                            <td><Button onClick={() => this.setState({editRow:""})}>Cancel Edit</Button> <br /> <br />
                                            <Button onClick={() => this.submitChanges(data.key, data.promotionName, data.promotionDetails, data.promotionStatus)}>Submit Changes</Button></td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                    <br />
                    <br />
                </Col>
            </Row>
        )
    }
}