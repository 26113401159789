import React from "react";
import { Switch } from "react-router-dom";
import Gallery from "./containers/Gallery.js";
import Home from "./containers/Home.js";
import AppliedRoute from "./components/AppliedRoutes";
import AdminLogin from "./containers/AdminLogin";
import AdminPage from "./containers/AdminPage";
import Book from "./containers/Book";
import Termsandconditions from "./containers/Termsandconditions";
import Album from "./containers/Album"
import Faqs from "./containers/Faqs"

export default ({ childProps}) =>
{
  return(
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} render={(props) => <Home {...props} ref=""/>}/>
    <AppliedRoute path="/gallery" exact component={Gallery} props={childProps} render={(props) => <Gallery {...props} ref=""/>}/>
    <AppliedRoute path="/album" component={Album} props={childProps}/>
    <AppliedRoute path="/admin" exact component={AdminLogin} props={childProps}/>
    <AppliedRoute path="/admin-edit" exact component={AdminPage} props={childProps} render={(props) => <AdminPage {...props} />}/>
    <AppliedRoute path="/book" component={Book} props={childProps}/>
    <AppliedRoute path="/faqs" component={Faqs} props={childProps}/>
    <AppliedRoute path="/termsandconditions" component={Termsandconditions} props={childProps}/>
  </Switch>
  )
}
  