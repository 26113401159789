import React, { Component } from 'react';
import { Container, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Row, Col, Spinner } from 'reactstrap';
import './Admin.css'
import PromotionUploader from '../components/PromotionUploader';
import PromotionTable from '../components/PromotionTable';
import TestimonialUploader from '../components/TestimonialUploader';
import TestimonialTable from '../components/TestimonialTable';
import AlbumUploader from '../components/AlbumUploader';
import AlbumTable from '../components/AlbumTable';
import GalleryUploader from '../components/GalleryUploader.js';
import AlbumEditor from '../components/AlbumEditor.js';
import DateManagerEditor from '../components/DateManagerEditor.js';
import DateManagerTable from '../components/DateManagerTable.js';
import BookingEditor from '../components/BookingTable.js';
import {AddBookedDate, GetBlockedDates, GetBookedDates} from "../services/dates-service.js"
import { GetTestimonials } from '../services/testimonials-service.js';
import { GetPromotions } from '../services/promotions-service.js';
import { GetAlbums } from '../services/albums-service.js';

export default class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.getBlockedDatesAgain = this.getBlockedDatesAgain.bind(this);
    this.getPromotionsFromDbAgain = this.getPromotionsFromDbAgain.bind(this);
    this.getTestimonialsFromDbAgain = this.getTestimonialsFromDbAgain.bind(this);
    this.getAlbumsFromDbAgain = this.getAlbumsFromDbAgain.bind(this);
    this.getDatesAgain = this.getDatesAgain.bind(this);
    this.state = {
      dropdownOpen: false,
      promotions: [],
      albums: [],
      dates: [],
      disabledDatesForCal: [],
      bookedDates: [],
      dropDownValue: 'Select an area',
      promotionTableContent: [],
      testimonialTableContent: [],
      albumTableContent: [],
      imagesTableContent: [],
      bookedDatesTableContent:[],
      blockedDatesTableContent:[],
      promotionsLoaded: true,
      testimonialsLoaded: true,
      albumsLoaded:true,
      imagesLoaded:false,
      bookedDatesLoaded:false,
      blockedDatesLoaded:false,
      dates2:[]
    }
  }

  componentWillMount(){
    console.log(this.props)
    if(typeof(this.props.authenticated !== 'undefined')){
      this.setState({authenticated:this.props.authenticated})
    }else{
      console.log(this.props.history.location.authenticated)
      this.setState({authenticated:this.props.history.location.authenticated})
    }
    
  }
  componentDidMount() {
    this.getPromotionsFromDb();
    this.getTestimonialsFromDb();
    this.getAlbumsFromDb();
    this.getBookedDatesFromDb();
    this.getBlockedDatesFromDb();
  }
  getPromotionsFromDb = async () => {
    const that = this;
    await GetPromotions()
      .then((response) => {
        that.setState({ promotionTableContent: response, promotionsLoaded: true }, function () {});
      }).catch(error => { console.log('request failed', error); });;
  }

  getTestimonialsFromDb = async () => {
    const that = this;
    await GetTestimonials()
      .then((result) => {
        that.setState({ testimonialTableContent: result, testimonialsLoaded: true }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  getAlbumsFromDb = async () => {
    const that = this;
    await GetAlbums()
      .then(function (jsonStr) {
        that.setState({ albumTableContent: jsonStr, albumsLoaded: true }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  getBookedDatesFromDb = async () => {
    const that = this;
    await GetBookedDates()
      .then(function (response) {
        that.setState({ bookedDatesTableContent:response, bookedDatesLoaded: true }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  getBlockedDatesFromDb = async () => {
    await GetBlockedDates().then(response =>{
      this.setState({dates2:response});
    }).then(b =>{
      console.log();
    })
    .then(e => {
      var disabledDatesArrary = [];
      var final = [];
      var i;
      var dates2 = []
      dates2 = this.state.dates2;
      
      for (i = 0; i < this.state.dates2.length; i++) {
        var Dataarray = [];
        Dataarray = dates2[i]['date'];
        final = { Dataarray }
        disabledDatesArrary.push(final);

      }
      console.log(Dataarray)
      var j;
      var finalDisabledDates = [];
      for (j = 0; j < disabledDatesArrary.length; j++) {
        var array = [];
        array = disabledDatesArrary[j]['Dataarray'];
        var date = new Date(array.seconds * 1000);
        finalDisabledDates.push(date)
      }
      console.log(finalDisabledDates)
      this.setState({ disabledDatesForCal: finalDisabledDates })
    }).then(f => {
      
    });
  }

  getBlockedDatesAgain() {
    this.getBlockedDatesFromDb();
  }

  getTestimonialsFromDbAgain(){
    this.getTestimonialsFromDb();
  }
  getPromotionsFromDbAgain(){
    this.getPromotionsFromDb();
  }

  getAlbumsFromDbAgain(){
    this.getAlbumsFromDb();
  }

  getDatesAgain(){
    console.log("hit the admin page!")
    this.getBookedDatesFromDb();
  }


  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  changeValue(e) {
    this.setState({ dropDownValue: e.currentTarget.textContent })
  }

  displayDropDown() {
    
  }
  displayArea(value) {
    switch (value) {
      case "Manage Promotions and Testimonials":
        return (
          <div>
            <h1>Promotions</h1>
            <PromotionUploader action={this.getPromotionsFromDbAgain} />
            <PromotionTable action={this.getPromotionsFromDbAgain} apiInfo={this.state.promotionTableContent} />
            <h1>Testimonials</h1>
            <TestimonialUploader action={this.getTestimonialsFromDb}/>
            <TestimonialTable action={this.getTestimonialsFromDb} apiInfo={this.state.testimonialTableContent}/>
          </div>
        );

      case "Manage Albums and Galleries":
        return (<div>
          <h1>Albums</h1>
          <AlbumUploader  action={this.getAlbumsFromDbAgain} />
          <AlbumTable action={this.getAlbumsFromDbAgain} apiInfo={this.state.albumTableContent}/>
          <h1>Gallery Uploader</h1>
          <GalleryUploader action={this.getAlbumsFromDbAgain} apiInfo={this.state.albumTableContent}/>
          <AlbumEditor api2Info={this.state.albumTableContent} action={this.getAlbumsFromDbAgain}/>
        </div>
        );
      case "Block Dates From Calendar":
        return (<div>
          <h1>Date Manager</h1>
          <DateManagerEditor apiInfo = {this.state.blockedDatesTableContent} dates={this.state.dates2} action={this.getBlockedDatesAgain} disabledDates={this.state.dates2} />
          <DateManagerTable dates={this.state.dates2} action={this.getBlockedDatesAgain} />
        </div>
        );
      case "Manage Bookings":
        return (<div>
          <h1>Manage Bookings</h1>
          <BookingEditor action={this.getDatesAgain} apiInfo={this.state.bookedDatesTableContent} bookedDates={this.state.bookedDates} />
        </div>
        );
      default:
        return (<div style={{ height: '70vh' }}>
          <h1>Welcome to The Admin Center</h1>
          <h3>Select an area to manage from the dropdown above</h3>
        </div>)
    }
  }

  displayPage(){
    console.log("loading")
      if (!this.state.promotionsLoaded && !this.state.albumsLoaded && !this.state.imagesLoaded 
        && !this.state.bookedDatesLoaded && !this.state.blockedDatesLoaded && !this.state.testimonialsLoaded) {
         
        return (
          <Container style={{ height: '70vh' }}>
          <Row>
            <Col>   
              <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
            </Col>
          </Row>
          <Row>
            <Col>
            <h1>Please wait whilst your admin center loads</h1>
            </Col>
          </Row>
          </Container>
        )
      }
      else {
        return (
          <Row>
            <Col>
              <Dropdown style={{ marginBottom: 50 }} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                  {this.state.dropDownValue}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem><div onClick={this.changeValue}>Manage Promotions and Testimonials</div></DropdownItem>
                  <DropdownItem><div onClick={this.changeValue}>Manage Albums and Galleries</div></DropdownItem>
                  <DropdownItem><div onClick={this.changeValue}>Block Dates From Calendar</div></DropdownItem>
                  <DropdownItem><div onClick={this.changeValue}>Manage Bookings</div></DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {
                this.displayArea(this.state.dropDownValue)
              }
            </Col>
          </Row>
        )
      }
  }
  render() {


    return (
      <Container fluid={true}>

        {this.displayPage()}


      </Container>
    )
  }

}