import React, { Component } from 'react';
import '../App.css';
import {
  Container,
  Row,
  Col,
  Button, Form, FormGroup, Label, Input, Card, CardTitle, CardText, Table, FormFeedback
} from 'reactstrap';
import 'react-accessible-accordion/dist/fancy-example.css';
import { db } from '../services/firebase.js';
import Slider from "react-slick";
import Banner from "../components/Banner";
import Features from "../images/features.png"
import './Home.css'
import logo from "../images/logo.jpeg"
import { ReCaptcha } from 'react-recaptcha-v3'
import { postDataResponse } from '../scripts/PostDataResponse';
import { Link as PageLink } from 'react-router-dom';
import { postData } from '../scripts/PostData';
import { GetActiveTestimonials } from '../services/testimonials-service.js';

export default class Home extends Component {
  constructor(childProps) {
    super(childProps);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.bookGold = this.bookGold.bind(this);
    this.bookSilver = this.bookSilver.bind(this);
    this.bookPlatinum = this.bookPlatinum.bind(this);
    this.bookBronze = this.bookBronze.bind(this);
    this.bookKids = this.bookKids.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.Testimonials = React.createRef();
    this.book = React.createRef();
    this.mmse = React.createRef();
    this.pp = React.createRef();
    this.contact = React.createRef();

    this.state = {
      displayBookingForm: true,
      isOpen: false,
      testimonials: [],
      testimonialInfo: [],
      ref: "",
      scrollTo: "",
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
      },
      validate: {
        emailState: '',
      },
      emailIsValid: false,
      email: "",
      name: "",
      eventType: "",
      package: "",
      message: "",
    };

  }

  validateEverything() {
    const { validate } = this.state
    var property = { ...this.state.validate }
    if (!this.state.emailState) {
      validate.emailState = 'has-danger'
      this.setState({ emailIsValid: false })
    }
    else {
      validate.emailState = 'has-success'
      this.setState(property)
    }

  }

  getTestimonialsFromDb = async () => {
    const that = this;
    await fetch("finesse/GetTestimonials.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (jsonStr) {

        that.setState({ testimonialInfo: jsonStr }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  async getTestimonials() {
    await GetActiveTestimonials().then((response) => {
      console.log(response)
      this.setState({testimonialInfo:response})
    })
  }

  componentWillMount() {

  }
  componentDidMount() {
    // this.getTestimonialsFromDb();
    this.setState({ authenticated: this.props.authenticated });
    this.getTestimonials();
    // if(typeof this.props.location.state === 'undefined')
    // {
    //   console.log ("undefined!")
    // }
    // else{
    //   this.setState({scrollTo:this.props.location.state.linkedItem})
    //   console.log(this.props.location.state.linkedItem);
    //   this.scrollTo(this.pp);
    // }
    if (typeof this.props.location.state != 'undefined') {

      this.handleScroll(this.props.location.state.linkedItem.title)
    }

    // if(this.props.ref > 0 )
    //  {
    //  this.setState({ref: this.props.location.state.ref}, function() {this.scrollTo(this.state.ref)})
    //  }
    //  else{
    //    console.log(this.props.location.state.ref)
    //    this.scrollTo(this.props.location.state.ref)
    //  }
    //this.checkForScrollItem();


  }

  handleScroll = (ref) => {
    if (ref === 'Prices and Packages') {
      setTimeout(() => {
        this.pp.current.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
    if (ref === 'Magic Mirror') {
      setTimeout(() => {
        this.mmse.current.scrollIntoView({ offset: '-100', behavior: 'smooth' })
      }, 500)
    }
    if (ref === 'Testimonials') {
      setTimeout(() => {
        this.Testimonials.current.scrollIntoView({ offset: '-100', behavior: 'smooth' })
      }, 500)
    }
    if (ref === 'Contact') {
      setTimeout(() => {
        this.contact.current.scrollIntoView({ offset: '-100', behavior: 'smooth' })
      }, 500)
    }

  }
  componentDidUpdate() {
    this.handleScroll()


  }

  async verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!  
    var data = {
      token: recaptchaToken
    }
    var result = await postDataResponse(JSON.stringify(data), 'finesse/verify.php')
    //this.props.location.passed = result.success
    console.log(result);
    //console.log(this.props.location.passed);
  }

  checkForScrollItem() {
    if (typeof this.props.location.state.linkedItem === 'undefined') {

    }
    else {
      this.scrollTo(this.props.location.state.linkedItem)
    }
  }
  scrollTo(ref) {
    //console.log("the ref is:" + ref)
    // ref.current.scrollIntoView({behavior: 'smooth',block: "center"})
    window.onload = function () {
      var el = document.getElementById('pricesandpackages');

      el.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  bookGold(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: '/book',
      search: '?package=gold',
      state: { packageType: 'Gold' }
    })
  }

  bookSilver(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: '/book',
      search: '?package=silver',
      state: { packageType: 'Silver' }
    })
  }

  bookBronze(event){
    event.preventDefault();
    this.props.history.push({
      pathname: '/book',
      search: '?package=bronze',
      state:{ packageType: 'Bronze'}
    })
  }

  bookKids(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: '/book',
      search: '?package=kids',
      state: { packageType: 'Kids' }
    })
  }

  bookPlatinum(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: '/book',
      search: '?package=platinum',
      state: { packageType: 'Platinum' }
    })
  }

  displayPage() {

   
      return (
        <div className="App">
          <Banner />


          <Container fluid={true} style={{ paddingBottom: 20, paddingRight: "0px", paddingLeft: "0px", margin: "0" }}>
            <Row style={{ marginRight: 0, marginLeft: 0 }}>
              <Col>
                <div ref={this.mmse} id="mmse" style={{}}>
                  <h1>Magic Mirror</h1>
                  <p>
                    Based in Staffordshire, our Magic Mirror is a great addition to any event, especially wedding receptions, birthday parties, proms and even the Christmas do. It’s time to take your guests on a magical journey with one of our Magic Mirror Photo Booths. Our interactive, touch screen mirrors are guaranteed to impress your guests whilst providing top quality photos and instant personalised prints.
                  </p>
                  <p>
                    <iframe title="vid1" className="mmImgLeft" width="560" height="315" src="https://www.youtube.com/embed/E2mqmyHIzHk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <iframe title="vid2" className="mmImg" width="560" height="315" src="https://www.youtube.com/embed/qGMqaRb07-c" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </p>
                  <p>
                    <Button><PageLink to="/book" className="book-nav"><strong style={{ color: 'white' }}>BOOK NOW</strong></PageLink></Button>
                  </p>
                </div>

              </Col>
            </Row>

            <Row style={{ margin: 0 }}>
              <Col>
                <div ref={this.pp} id="pricesandpackages">
                  <h1>Prices and Packages</h1>
                </div>
              </Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col>
                <div ref={this.book} id="book">
                  <Row>
                    <Col sm={6} md={4}>
                      <Card body>
                        <CardTitle className="silverTitle">Silver - £350</CardTitle>
                        <CardText>
                          <li style={{ listStyle: 'none' }}>2 Hour Hire</li>
                          <li style={{ listStyle: 'none' }}>Unlimited Visits to Mirror</li>
                          <li style={{ listStyle: 'none' }}>USB with all the Photos</li>
                          <li style={{ listStyle: 'none' }}>Unlimited Instant Prints</li>
                          <li style={{ listStyle: 'none' }}>Customisable Photos</li>
                          <li style={{ listStyle: 'none' }}>Personalise Message on your Photos</li>
                          <li style={{ listStyle: 'none' }}>Trained Mirror Attendant</li>
                          <li style={{ listStyle: 'none' }}>Free Delivery including Setup Removal*</li>
                          <li style={{ listStyle: 'none' }}>VIP Ropes & Red Carpet</li>
                          <li style={{ listStyle: 'none' }}>Choice of 5 Background Templates to choose</li>
                          <br/>
                        </CardText>
                        <Button onClick={this.bookSilver} className="cardButton">BOOK NOW</Button>
                      </Card>
                    </Col>
                    <Col sm={6} md={4}>
                      <Card body>
                        <CardTitle className="goldTitle">Gold - £450</CardTitle>
                        <CardText>
                          <li style={{ listStyle: 'none' }}>3 Hour Hire</li>
                          <li style={{ listStyle: 'none' }}>Unlimited Visits to Mirror</li>
                          <li style={{ listStyle: 'none' }}>Double Prints with Guest Book</li>
                          <li style={{ listStyle: 'none' }}>USB with all the Photos</li>
                          <li style={{ listStyle: 'none' }}>Unlimited Instant Prints</li>
                          <li style={{ listStyle: 'none' }}>Interactive Animations & Sounds</li>
                          <li style={{ listStyle: 'none' }}>Customisable Photos</li>
                          <li style={{ listStyle: 'none' }}>Personalise Message on your Photos</li>
                          <li style={{ listStyle: 'none' }}>Trained Mirror Attendant</li>
                          <li style={{ listStyle: 'none' }}>Free Delivery including Setup Removal*</li>
                          <li style={{ listStyle: 'none' }}>VIP Ropes & Red Carpet</li>
                          <li style={{ listStyle: 'none' }}>Unique Customisable Template</li>
                        </CardText>
                        <Button onClick={this.bookGold} className="cardButton">BOOK NOW</Button>
                      </Card>
                    </Col>
                    <Col sm={6} md={4}> 
                      <Card body>
                        <CardTitle className="platTitle">Platinum - £540</CardTitle>
                        <CardText>
                          <li style={{ listStyle: 'none' }}>4 Hour Hire</li>
                          <li style={{ listStyle: 'none' }}>Unlimited Visits to Mirror</li>
                          <li style={{ listStyle: 'none' }}>Double Prints with Guest Book</li>
                          <li style={{ listStyle: 'none' }}>USB with all the Photos</li>
                          <li style={{ listStyle: 'none' }}>Unlimited Instant Prints</li>
                          <li style={{ listStyle: 'none' }}>Interactive Animations & Sounds</li>
                          <li style={{ listStyle: 'none' }}>Customisable Photos</li>
                          <li style={{ listStyle: 'none' }}>Personalise Message on your Photos</li>
                          <li style={{ listStyle: 'none' }}>Trained Mirror Attendant</li>
                          <li style={{ listStyle: 'none' }}>Free Delivery including Setup Removal*</li>
                          <li style={{ listStyle: 'none' }}>VIP Ropes & Red Carpet</li>
                          <li style={{ listStyle: 'none' }}>Unique Customisable Template</li>
                        </CardText>
                        <Button onClick={this.bookPlatinum} className="cardButton">BOOK NOW</Button>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      * FREE delivery within 30 miles of ST8
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table dark bordered responsive hover>
                        <thead>
                          <tr>
                            <th colSpan="2">Add Ons</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>USB</td><td>£14.99</td>
                          </tr>

                          <tr>
                            <td>Fridge Magnet</td><td>£3.00</td>
                          </tr>

                          <tr>
                            <td>Keyring</td><td>£3.00</td>
                          </tr>

                          <tr>
                            <td>Bottle Opener</td><td>£4.00</td>
                          </tr>

                          <tr>
                            <td>Green Screen *</td><td>£49.99</td>
                          </tr>

                          <tr>
                            <td>Customised Backgrounds</td><td>£29.99</td>
                          </tr>
                        </tbody>
                      </Table>
                      *Greenscreen requires venue/event to have enough space (5m by 5m preferably)
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ backgroundColor: '#f5bff6', color: 'white', marginLeft: '15px', marginRight: '15px' }}>

                      <img className="features" alt="features" src={Features} />

                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="testimonials" id="testimonials" >
              <Col>
                <div ref={this.Testimonials}>
                  <h1>Testimonials</h1>
                  <div>

                    <div style={{ background: "#A0A0A0", color: "#ffffff", margin: 0 }}>
                      <Slider ref={slider => (this.slider = slider)} {...this.state.settings}>
                        {this.state.testimonialInfo.map((data, i) => {
                          return (
                            <div key={i}>
                              <div className="testimonialText">{data.TestimonialText}</div>
                              <div className="testimonialDetails">{data.ClientName}</div>
                            </div>
                          )
                        })}

                      </Slider>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row id="contactRow">
              <Col>
                <div ref={this.contact} id="contact">
                  <h1>Contact</h1>
                  <p> If you have any questions relating to our products and services, please call, text or WhatsApp us on 075405400773/07799866240 or contact us below</p>
                  {this.displayBookingForm()}
                </div></Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col>

              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  }
  formSubmit() {
    if (this.state.emailIsValid) {
      var data = {
        email: this.state.email,
        name: this.state.name,
        eventType: this.state.eventType,
        package: this.state.package,
        message: this.state.message
      }
      postData(JSON.stringify(data), '/finesse/mailer3.php');
      this.setState({ displayBookingForm: false })
    }
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
      this.setState({ emailIsValid: true })
    } else {
      validate.emailState = 'has-danger'
      this.setState({ emailIsValid: false })
    }
    this.setState({ validate })
  }

  displayBookingForm() {
    if (this.state.displayBookingForm) {
      return (
        <Form>
          <FormGroup row>
            <Label for="email" sm={2}>Email</Label>
            <Col sm={10}>
              <Input type="email"
                name="email"
                id="email"
                valid={this.state.validate.emailState === 'has-success'}
                invalid={this.state.validate.emailState === 'has-danger'}
                placeholder="Your Email" onChange={(e) => {
                  this.validateEmail(e)
                  this.handleChange(e)
                }}
              />
              <FormFeedback valid />
              <FormFeedback>
                Uh oh! Looks like there is an issue with your email. Please input a valid email.
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="name" sm={2}>Name</Label>
            <Col sm={10}>
              <Input type="text" name="name" id="name" placeholder="Your Name" onChange={(e) => { this.handleChange(e) }} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="eventType" sm={2}>Type of Event</Label>
            <Col sm={10}>
              <Input type="text" name="eventType" id="eventType" placeholder="Wedding? Party?" onChange={(e) => { this.handleChange(e) }} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="package" sm={2}>Package</Label>
            <Col sm={10}>
              <Input type="text" name="package" id="package" placeholder="" onChange={(e) => { this.handleChange(e) }} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="message" sm={2}>Your Messsage</Label>
            <Col sm={10}>
              <Input type="textarea" name="message" id="message" onChange={(e) => { this.handleChange(e) }} />
            </Col>
          </FormGroup>
          <ReCaptcha
            sitekey="6LcddqoUAAAAAJMJPUp-FZYWoLRfNxuRoP92g7pp"
            action='homepage'
            verifyCallback={this.verifyCallback}
          />
          <FormGroup check row>
            <Col sm={{ size: 10, offset: 2 }}>
            <Button onClick={(e) => { this.validateEverything(e); this.formSubmit();}}>Submit</Button>
            </Col>
          </FormGroup>
        </Form>
      )
    }
    else {
      return (
        <h3>
          Thank you for your message, we will be in touch within 24 hours
          </h3>
      )
    }
  }

  render() {

    return (

      <div>
        {this.displayPage()}
      </div>
    );
  }
}