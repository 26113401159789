import React, { Component } from 'react';
import BookOrderIn from './BookOrderIn.js';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { GetDateFromTimeStamp } from '../Helpers/DateHelper.js';

export default class HistoricBookingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historicTableData:[],
            products: [],
            apiInfo:[],
        }
    }
componentWillMount(){
    this.setState({ apiInfo: this.props.apiInfo, products: this.props.apiInfo })
    this.EditData(this.props.apiInfo);

}

componentWillReceiveProps(nextProps){
    this.setState({apiInfo:nextProps.apiInfo}); 
    this.EditData(nextProps.apiInfo);
}

EditData(data) {
    if (data != null) {
        var i;
        var dataItem = [];
        for (i = 0; i < data.length; i++) {
            dataItem = data[i];
            var newDate = this.updateDate([dataItem['date']])
            dataItem['date'] = newDate;

        }
    }
    this.setState({ products: data });
}

updateDate(date) {
        
    var d = new Date();
    d.setTime(date);
    return (
        d.toLocaleString('en-GB').substring(0, d.toLocaleString('en-GB').indexOf(','))
    )
}
convertDate(date) {
    var d = new Date();
    d.setTime(date);
    return (
        d.toLocaleString('en-GB').substring(0, d.toLocaleString('en-GB').indexOf(','))
    )
}

isActive = (status) => {
    if (status) {
        return "Booked"
    }
    else {
        return "Awaiting customer call"
    }
};

getStatus(s) {
    
    if(typeof s != 'undefined'){
            
        switch(s){
            
            case "Awaiting Customer Call":
                return "Awaiting Customer Call";
            case "Cancelled":
                return "Cancelled Order";
            case "Booked":
                return "Order Booked";
            case "":
                return "Awaiting Customer Call";
            case "AFI":
                return "Awaiting Further Information";
            default:
                return "Awaiting Further Information";
        }
      }
      else{
          return "Awaiting Further Information"
      }
}





    render() {
        return (
            <div>
<ReactTable
                    data={this.state.products}
                    columns={[
                        {
                            Header: "Booking Details",
                            columns: [
                                {
                                    Header: "Booking Id",
                                    accessor:"BookingId",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Booking Id</div>
                                },
                                {
                                    Header: "Date",
                                    id: "Date",
                                    accessor: d => GetDateFromTimeStamp(d.Date),
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Date</div>
                                },
                                {
                                    Header: "Full Name",
                                    accessor: "fullName",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Full Name</div>
                                },
                                {
                                    Header: "Location",
                                    accessor: "location",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Location</div>
                                },
                                {
                                    Header: "Email",
                                    accessor: "Email",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Email</div>
                                },
                                {
                                    Header: "Telephone",
                                    accessor: "Telephone",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Telephone</div>
                                },
                                {
                                    Header: "Order Status",
                                    id: "status",
                                    accessor: d => this.getStatus(d.booked),
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Order Status</div>
                                },
                                {
                                    Header: "Cx Comments",
                                    accessor:"Comments",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Cx Comments</div>
                                }
                            ]
                        },
                        {
                            Header: "Package",
                            columns: [
                                {
                                    Header: "Package",
                                    accessor: "Package",
                                    Footer: () => <div style={{ textAlign: "center" }}>Package</div>
                                }
                            ]
                        },
                        {
                            Header: "Expand",
                            columns: [
                                {
                                    expander: true,
                                    Header: () => <strong>More</strong>,
                                    width: 65,
                                    Expander: ({ isExpanded, ...rest }) =>
                                        <div>
                                            {isExpanded
                                                ? <span>&#x2299;</span>
                                                : <span>&#x2295;</span>}
                                        </div>,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    },
                                    Footer: () => <span>&hearts;</span>
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    SubComponent={(v) => <BookOrderIn data={v.row} action={this.updateData} />}
                />
            </div>
        )
    }
}