import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col,Spinner, Button } from 'reactstrap';
import {postDataResponse} from '../scripts/PostDataResponse';
import {postData} from '../scripts/PostData';
import { DeleteAlbumImage, GetAlbumImageData } from '../services/albums-service';

export default class AlbumEditor extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.state = {
            albums: [],
            dropDownValue: "Please Select An Album",
            images: [],
            api2Info: [],
            apiInfo: [],
            GotAlbums:false,
            albumId:''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({api2Info:nextProps.api2Info});  
      }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidMount() {
        
    }

    async changeValue(e){
        this.setState({dropDownValue: e.currentTarget.textContent, albumId:e.currentTarget.getAttribute('value')})
        await GetAlbumImageData(e.currentTarget.getAttribute('value')).then((response) => {
            this.setState({ apiInfo:response, GotAlbums:true })
        })
    }

    componentWillMount(){
        this.setState({api2Info: this.props.api2Info});
      }

      DeleteDocument = async (collection, documentId) => {
        var data = {
            key: documentId,
            table: collection,
        }
        await postData(JSON.stringify(data), 'finesse/DeleteFromDatabase.php');
        var drpdata ={
            albumName: this.state.dropDownValue,
        }
    const result= await postDataResponse(JSON.stringify(drpdata),'finesse/QueryImagesTable.php')
 
            this.setState({apiInfo:result,GotAlbums:true});
    };

    async DeleteImage(imageUrl){
        await DeleteAlbumImage(imageUrl).then(() => {
            GetAlbumImageData(this.state.albumId).then((response) => {
                this.setState({ apiInfo:response, GotAlbums:true })
            })
        })
    }

    displayTable() {
      

        if (this.state.dropDownValue === "Please Select An Album") {
            return (
                <div></div>
            )
        }
        else{
            if(this.state.GotAlbums){
                if (this.state.apiInfo.length > 0) {
                    return (
                        <table id="albumsTable" style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th>Gallery Image</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.apiInfo.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><img
                                                style={{ maxHeight: 100 }}
                                                src={data.imageUrl}
                                                alt="ImageName"
                                            /></td>
                                            <td><Button onClick={() => {this.DeleteImage(data.imageUrl);}}>Delete</Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                }
                else{
                    return (
                        <div>No Images In Album</div>
                    )
                }
            }
            else{
                return (
                    <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
                )
            }
        }
    }

    render() {
        return (
            <div>
                <h2>Edit an albums images</h2>
                <Row>
                    <Col>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret>
                                {this.state.dropDownValue}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {this.state.api2Info.map((data, i) => {
                                    return (
                                        <DropdownItem><div value={data.id} onClick={(e) => this.changeValue(e)}>{data.AlbumName}</div></DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.displayTable()}
                    </Col>
                </Row>
            </div>
        )
    }
}