import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Row, Col, Button } from 'reactstrap';
import { db } from '../services/firebase.js';
import FileUploader from 'react-firebase-file-uploader';
import {postData} from '../scripts/PostData';
import { storage } from '../services/firebase.js';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
export default class GalleryUploader extends Component {
    constructor(props) {
        super(props);
        this.toggle=this.toggle.bind(this);
        this.state = {
            testimonialName: "",
            testimonialText: "",
            testimonials: [],
            albums:[],
            dropDownValue:"Please Select An Album",
            imageUploads:[],
            apiInfo:[],
            progress:0,
            imageUrls:[],
            files:[]
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({apiInfo:nextProps.apiInfo});  
      }

    componentWillMount(){
        this.setState({ apiInfo: this.props.apiInfo });
      }

      toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleChange = (e) => {
        e.preventDefault()
        this.setState({ files: Array.from(e.target.files) });
    }

    handleUpload = (e) => {
        e.preventDefault();
        const { files } = this.state;
        const uploadTasks = files.map(file => {
            const storageRef = ref(storage, `images/${this.state.apiInfo[0].id}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState(prevState => ({
                            progress: { ...prevState.progress, [file.name]: progress }
                        }));
                    },
                    (error) => {
                        console.error('Error uploading file:', error);
                        reject(error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        this.setState(prevState => ({
                            imageUrls: [...prevState.imageUrls, downloadURL]
                        }));
                        resolve();
                    }
                );
            });
        });

        Promise.all(uploadTasks).then(() => {
            alert('All files uploaded successfully!');
        }).catch((error) => {
            alert('Error uploading some files:', error);
        });
    }

    onAlbumFormSubmit() {
        var data = {
            AlbumName: this.state.albumName,
            BlogText: this.state.blogText,
            Status: true
        }
        db.collection('albums').doc().set(data);
    }

    changeValue(e) {
        this.setState({ dropDownValue: e.currentTarget.textContent })
    }
    updateKey(k){
        this.setState({albumKey:k})
    }
    clearForm() {
        this.setState({
            dropDownValue:"Please Select An Album",
            imageUploads:[],
            apiInfo:[],
            progress:0,
            imageUrls:[],
            files:[]
        })
    }

    render() {
        return (
            <>
            <Row>
                <Col>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret>
                            {this.state.dropDownValue}
                        </DropdownToggle>
                        <DropdownMenu right>
                            {this.state.apiInfo.map((data, i) => {
                                return (
                                    <DropdownItem><div onClick={(e) => {this.changeValue(e); this.updateKey(data.Key);}} value={data.Key}>{data.AlbumName}</div></DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </Dropdown>
                    <Row>
                        <Col>
                    <Label> Album Selected: {this.state.dropDownValue} <br/></Label>
                    </Col>
                  </Row>
                  {this.state.dropDownValue !== "Please Select An Album" && 
                  <div>
                <input type="file" onChange={(e) => this.handleChange(e)} multiple />
                <button onClick={(e) => this.handleUpload(e)}>Upload</button>
                {Object.keys(this.state.progress).map(fileName => (
                    <div key={fileName}>
                        <span>{fileName}: {this.state.progress[fileName]}%</span>
                    </div>
                ))}
                {this.state.imageUrls.map((url, index) => (
                    <div key={index}>
                        <img style={{maxHeight:200, maxWidth:200}}src={url} alt={`Uploaded file ${index + 1}`} />
                    </div>
                ))}
                <Col>
                    <Button onClick={() => this.clearForm()}>Clear</Button>
                </Col>
            </div>
                }
                </Col>
                
            </Row>
</>
        )
    }
}