import React, { Component } from 'react';
import './App.css';
import Routes from "./Routes";
import { withRouter } from 'react-router-dom';
import 'react-accessible-accordion/dist/fancy-example.css';
import NavbarThing from './components/Navbar.js'
import Crouton from 'react-crouton';
import { Link } from "react-scroll";
import { Link as PageLink } from 'react-router-dom';
import up from './images/icons8-slide-up-50.png';
import fb from './images/icons8-facebook-old-48.png';
import inst from './images/icons8-instagram-48.png';
import { postDataResponse } from './scripts/PostDataResponse';
import { loadReCaptcha } from 'react-recaptcha-v3'
import {auth} from "./services/firebase.js"

class App extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.displayAvailablePromotion = this.displayAvailablePromotion.bind(this);
    this.state = {
      isOpen: false,
      promotionAvailable: true,
      promotionMessage: "abc",
      finalArr: [],
      testimonialInfo:[],
      loading: false,
      authenticated:false
    };
  }

  async checkDbForSession(e){
    var data = {
      sessionKey : e
    }
    await postDataResponse(JSON.stringify(data), 'finesse/getSession.php');

  }
  componentWillMount() {
    try{
    const request = indexedDB.open('firebaseLocalStorageDb');

        request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['firebaseLocalStorage'], 'readonly');
            const store = transaction.objectStore('firebaseLocalStorage');
            const getRequest = store.get('firebase:authUser:AIzaSyCfhoEhBx6RzLv0IkCaDXsJljEnjI7tOPI:[DEFAULT]');

            getRequest.onsuccess = (event) => {
                const user = event.target.result;
                console.log("user exists")
                this.setState({ authenticated: !!user }); // Sets authenticated to true if user exists, otherwise false
            };

            getRequest.onerror = (event) => {
                console.error('Error fetching user data:', event);
                this.setState({ authenticated: false }); // Ensure it's set to false in case of an error
            };
        };

        request.onerror = (event) => {
            console.error('Error opening IndexedDB:', event);
            this.setState({ authenticated: false }); // Ensure it's set to false in case of an error
        }
    this.listener = auth.onAuthStateChanged((authenticated) => {
      if(authenticated){
        this.setState({authenticated:true});
        this.props.history.push({
        authenticated:this.state.authenticated
       })
      }
      }
    
    );
  }
  catch(exc){
    this.setState({ authenticated: false }); 
  }
}

  setPromoData(finalArr) {
    var message = finalArr[0]["promotionDetails"];
    var backgroundColor = finalArr[0]["promotionBackgroundColour"];
    var fontColor = finalArr[0]["promotionFontColour"]
    this.setState({ promotionMessage: message, backgroundColor: backgroundColor, fontColor: fontColor, loading: true })
  }
  componentDidMount() {
    // loadReCaptcha('6LcddqoUAAAAAJMJPUp-FZYWoLRfNxuRoP92g7pp');
  }

  checkForAvailablePromotions() {
    //TODO: if count of promotions > 1 return true
  }

  setPromotionalInfo() {
    if (this.state.promotionAvailable) {

    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  listener() {
 
  }
  displayAvailablePromotion() {
    if (this.state.promotionAvailable) {
      return (
        <Crouton
          message={this.state.promotionMessage}
          type="info"
          id= {Date.now()}
          buttons="close"
          onDismiss= {this.listener}
          children={true}
        ><div style={{ background: `${this.state.backgroundColor}`, color: `${this.state.fontColor}` }}>{this.state.promotionMessage}</div></Crouton>
      )
    }
  }
  displayLocalStorage(){
    if(localStorage.getItem('authenticated')){
      return(
        <div>
          true!
        </div>
      )
    }
  }



  render() {

    const childProps = {
authenticated : this.state.authenticated
    };
    console.log(childProps)
    //   var mmse = ReactDOM.findDOMNode(this.refs.mmse)
    return (
      <div className="App">
        <div className = "body-content">
        <div>
          {this.state.loading ? (
            this.displayAvailablePromotion()
          ) : null}

   
        <NavbarThing authenticated={this.state.authenticated} />
     
        <Routes childProps={childProps} />

        </div>
        <footer className="footer" style={{backgroundColor: "#e9e9e9" }}>
          <PageLink to="/termsandconditions">Terms and Conditions</PageLink><br />

          Copyright Finesse Photos 2019 Company Registration Number 11526910           <Link
              to={'magic-mirror'}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={this.toggle}
              style={{cursor:'pointer'}}
            >
            <img alt="back to top" style={{float:"right"}} src={up}/></Link><br/>
            <a href="https://www.facebook.com/FinessephotoUK/"><img alt="facebook" src={fb} style={{height:48, width:48}}/></a>
          <a href="https://www.instagram.com/finessephotosuk/"><img alt="instagram" src={inst} style={{height:48, width:48}}/></a>
        </footer>

        </div>

      </div>

    );
  }
}

export default withRouter(App);
