import React, { Component } from 'react';
import {Container, Row, Col,Spinner} from 'reactstrap';
import Gallery from 'react-grid-gallery';
import { postDataResponse } from '../scripts/PostDataResponse';
import { GetAlbumById, GetAlbumImageData, GetAlbums } from '../services/albums-service';
export default class AlbumDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryLoaded:false,
            apiInfo:[],
            apiInfo2:[],
        }
    }
      async getImageGallery(){
        var albumKey = this.props.location.state.albumKey;
        await GetAlbumImageData(albumKey).then(result => {
          this.setState({apiInfo:result, galleryLoaded: true});
        })
      }

      async getBlogText(){
        var albumKey = this.props.location.state.albumKey;
        await GetAlbumById(albumKey).then((response) => {
          this.setState({apiInfo2:response})
        });
      }

      componentDidMount(){
        this.getBlogText();
        this.getImageGallery();
      }
      displayAlbum(IMAGES){
        if(!this.state.galleryLoaded)
        {
          return(
            <Container style={{ height: '70vh' }}>
            <Row>
              <Col>   
                <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
              </Col>
            </Row>
            <Row>
              <Col>
              <h1>Gallery Loading....</h1>
              </Col>
            </Row>
            </Container>
          )
        }
        else{
          return(
<Container>
                <Row>
                <Col>
                    <Gallery images={IMAGES}/>
                </Col>
                </Row>
                </Container>
          )
        }
      }

    render() {
        const IMAGES = 
    this.state.apiInfo.map((data,i) => {
      var img = new Image();
      
      img.onload = function(){
        return this.height
      };
      img.onload = function(){
        return this.width
      };
      img.src = data.imageName;
      var width = img.width;
      var height = img.height;
      return(
        {
          src: data.imageUrl,
          thumbnail: data.imageUrl,
          thumbnailWidth: width/5,
          thumbnailHeight: height/5,
        }
      )
    })
        return (
            <div>
                {this.state.apiInfo2.map((data, i)=> {
                    return(
                        
                            <div><h4>{data.BlogText}</h4></div>
                        
                    )
                })}
                {this.displayAlbum(IMAGES)}
                
            </div>
        )
    }
}