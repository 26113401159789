import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, storage } from "./firebase";
import { deleteObject, getDownloadURL, listAll, ref } from "firebase/storage";

export async function GetAlbums(){
    const querySnapshot = await getDocs(collection(db, "albums"));
    var albumsArray = []
    
    querySnapshot.forEach((doc) => {
        var album = { id: doc.id, ...doc.data() }
        albumsArray.push(album)
      });
      return albumsArray;
  }

  export async function GetActiveAlbums(){
    const albumsRef = collection(db, "albums");
    const q = await query(albumsRef, where('Status', '==', true))
    const querySnapshot = await getDocs(q);
    var albumsArray = []
    
    querySnapshot.forEach((doc) => {
      var album = { id: doc.id, ...doc.data() }
        albumsArray.push(album)
      });
      return albumsArray;
  }

  export async function GetAlbumById(id){
    const albumsRef = collection(db, "albums");
    const q = await query(albumsRef, where('id', '==', id))
    const querySnapshot = await getDocs(q);
    var albumsArray = []
    
    querySnapshot.forEach((doc) => {
      var album = { id: doc.id, ...doc.data() }
        albumsArray.push(album)
      });
      return albumsArray;
  }



  export async function UpdateAlbumStatus(status, id){
  const docRef = doc(db, "albums", id);
  await updateDoc(docRef, {
    Status: !status
  });
}

export async function DeleteAlbum(id, folderCoverUrl){
    const filePath = extractPathFromUrl(folderCoverUrl)
    console.log(filePath)
    const fileRef = ref(storage, filePath)
    try {
        await deleteObject(fileRef);
    } catch (error) {
        alert('Error deleting file:', error);
    }
    const folderRef = ref(storage, `images/${id}`);

    try {
        const res = await listAll(folderRef);

        const deletePromises = res.items.map((itemRef) => deleteObject(itemRef));
        
        await Promise.all(deletePromises);
    } catch (error) {
        console.error('Error deleting folder contents:', error);
    }
    const docRef = doc(db, "albums", id);
    await deleteDoc(docRef);
}

export async function AddAlbum(data){
    await addDoc(collection(db, "albums"), data)
}

export async function GetAlbumImageData(folderId){
    const listRef = ref(storage, `images/${folderId}`);

    try {
        const res = await listAll(listRef);

        const urls = await Promise.all(res.items.map((itemRef) => getDownloadURL(itemRef)));
        var urlArray = []
        urls.forEach((url) => {
            var image = {imageUrl: url}
            urlArray.push(image)
        });

        return urlArray;
    } catch (error) {
        console.error("Error listing images:", error);
    }
}

export async function DeleteAlbumImage(imageUrl){
    console.log(imageUrl)
    const filePath = extractPathFromUrl(imageUrl)
    console.log(filePath)
    const fileRef = ref(storage, filePath)
    try {
        await deleteObject(fileRef);
        alert('File deleted successfully!');
    } catch (error) {
        alert('Error deleting file:', error);
    }
}

function extractPathFromUrl(url) {
    const start = url.indexOf("/o/") + 3; // Find the start of the encoded path
    const end = url.indexOf("?alt=media"); // Find the end of the encoded path
    const encodedPath = url.substring(start, end); // Extract the encoded path
    const decodedPath = decodeURIComponent(encodedPath); // Decode the path
    return decodedPath;
}