import React, { Component } from 'react';
import '../App.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import logo from '../images/logo.jpeg'
import { Link} from "react-scroll";
import {Link as PageLink} from 'react-router-dom';
import fb from '../images/icons8-facebook-old-48.png';
import inst from '../images/icons8-instagram-48.png';


export default class NavbarThing extends Component  {
   constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.navLink = this.navLink.bind(this);
    this.book = React.createRef();
    this.state = {
      isOpen: false
    };
    
  }

  componentWillMount(){
    if(localStorage.getItem('authenticated'))
    this.setState({authenticated:true})
  }

  componentDidMount(){
    this.faqs = React.createRef();
    this.prices = React.createRef();
    
  }
  scroll(ref) {
    ref.current.scrollIntoView({behavior: 'smooth',block: "center"})
  }



  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  navLink(title, to){
    if(window.location.pathname === "/")
    {
     if(title==="BOOK NOW")
     {
      return (
        <li className="nav-item book-nav" style={{cursor: "pointer"}}>
<PageLink to="/book" className="book-nav" onClick={this.toggle}><strong style={{color:'white'}}>{title}</strong></PageLink>
          </li>
      )
     }
     else{

     
        return (
          <li className="nav-item" style={{cursor: "pointer"}}>
          <Link
              to={to}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={this.toggle}
            >
            {title}
            </Link>
            </li>
        )
        }
    }
    else{
      if(title==="BOOK NOW"){
        return(
          <li className="nav-item book-nav" style={{cursor: "pointer"}}>
            <PageLink to="/book"  onClick={this.toggle}><strong style={{color:'white'}}>{title}</strong></PageLink>
          </li>
        )
      }
      return(
        <li className="nav-item" style={{cursor: "pointer"}}>
          <PageLink to={{
            pathname:"/",
            state: {
            linkedItem: {title}
            }
          }}  onClick={this.toggle}>{title}</PageLink>
        </li>
      )
    }
  }

  displayNavbar(){
    if(this.props.authenticated)
    return(
      <div>
        <div style={{backgroundColor:"pink"}}>
          All Attendants will be LFT before the event.<br/>
          All props are sanitised before and during the event
        </div>
        <div>
        <Navbar color="white" light expand="md" sticky={'top'} style={{maxHeight:90}}>

<NavbarBrand href="/" style={{backgroundImage: "url(" + logo + ")"}}></NavbarBrand>
<a href="https://www.facebook.com/FinessephotoUK/"><img alt="facebook" src={fb} style={{height:48, width:48}}/></a>
<a href="https://www.instagram.com/finessephotosuk/"><img alt="instagram" src={inst} style={{height:48, width:48}}/></a>
<NavbarToggler onClick={this.toggle} />
<Collapse isOpen={this.state.isOpen} navbar>
  <Nav className="ml-auto" navbar>
  <NavItem>
    <PageLink to="/admin-edit"  onClick={this.toggle}>Admin</PageLink>
  </NavItem>
  {this.navLink("Magic Mirror", "mmse")}
  {this.navLink("Prices and Packages", "pricesandpackages")}
  {this.navLink("Testimonials","testimonials")}
  {this.navLink("Contact", "contact")}
  <NavItem>
    <PageLink to="/gallery"  onClick={this.toggle}>Gallery</PageLink>
  </NavItem>
  <NavItem>
    <PageLink to="/faqs"  onClick={this.toggle}>FAQS</PageLink>
  </NavItem>
   {this.navLink("BOOK NOW", "book")}
  </Nav>
</Collapse>
</Navbar>
</div>
</div>

    )
    else{
      return(
        <div>
        <div style={{backgroundColor:"#f5bff6", fontWeight:"bold"}}>
          COVID Update: All Attendants will be LFT before the event --  All props are sanitised before and during the event
        </div>
        <div>
        <Navbar color="white" light expand="md" sticky={'top'} style={{maxHeight:90}}>

        <NavbarBrand href="/" style={{backgroundImage: "url(" + logo + ")", width:50, marginTop:25, backgroundSize:"contain", marginLeft:20,backgroundRepeat:"no-repeat"}}></NavbarBrand>
        <a href="https://www.facebook.com/FinessephotoUK/"><img alt="facebook" src={fb} style={{height:48, width:48}}/></a>
        <a href="https://www.instagram.com/finessephotosuk/"><img alt="instagram" src={inst} style={{height:48, width:48}}/></a>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
        <Nav className="ml-auto" navbar>
        {this.navLink("Magic Mirror", "mmse")}
        {this.navLink("Prices and Packages", "pricesandpackages")}
        {this.navLink("Testimonials","testimonials")}
        {this.navLink("Contact", "contact")}
        <NavItem>
          <PageLink to="/gallery"  onClick={this.toggle}>Gallery</PageLink>
        </NavItem>
        <NavItem>
          <PageLink to="/faqs"  onClick={this.toggle}>FAQS</PageLink>
        </NavItem>
         {this.navLink("BOOK NOW", "book")}
        </Nav>
        </Collapse>
      </Navbar>
      </div>
</div>
      
      )
    }
  }

  render() {
    return (
      
<div>

{this.displayNavbar()}
 
 </div>
    );
  }
}