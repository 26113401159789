import { addDoc, collection, query, getDocs, orderBy, limit, where, updateDoc, doc, deleteDoc } from "firebase/firestore";
import {db} from "./firebase";

export async function GetActiveTestimonials(){
    const testimonialsRef = collection(db, "testimonials");
    const q = await query(testimonialsRef, where('Active', '==', true))
    const querySnapshot = await getDocs(q);
    var testimonialsArray = []
    
    querySnapshot.forEach((doc) => {
        var testimonial = { id: doc.id, ...doc.data() }
        testimonialsArray.push(testimonial)
      });
      console.log(testimonialsArray)
      return testimonialsArray;
}

export async function GetTestimonials(){
  const querySnapshot = await getDocs(collection(db, "testimonials"));
  var testimonialsArray = []
  
  querySnapshot.forEach((doc) => {
      var testimonial = { id: doc.id, ...doc.data() }
      testimonialsArray.push(testimonial)
    });
    return testimonialsArray;
}

export async function UpdateTestimonialStatus(id, status){
  const docRef = doc(db, "testimonials", id);
  await updateDoc(docRef, {
    Active: status
  });
}

export async function UpdateTestimonialInfo(id, clientName, testimonialText){
  const docRef = doc(db,"testimonials", id);
  await updateDoc(docRef,{
    ClientName: clientName,
    TestimonialText:testimonialText
  })
}

export async function DeleteTestimonial(id){
  console.log(id)
  await deleteDoc(doc(db, "testimonials", id));
}

export async function AddTestimonial(data){
    await addDoc(collection(db, "testimonials"), {
        ClientName:data.ClientName,
        TestimonialText: data.TestimonialText,
        Active:data.Active
      }).then(() =>{
      });
}
