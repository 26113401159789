import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Button, Row} from 'reactstrap';
import {postData} from '../scripts/PostData.js';
import { SketchPicker } from 'react-color';
import { AddPromotion } from '../services/promotions-service.js';

export default class PromotionUploader extends Component  {
    constructor(props) {
     super(props);
     this.onFormSubmit = this.onFormSubmit.bind(this);
     this.state = {
        background:"#000",
        fontColor:"#fff",
     };
     
   }

   handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  handleFontChangeComplete = (color) => {
    this.setState({ fontColor: color.hex });
  };
   handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };
  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleFontClick = () => {
    this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker })
  };
  handleFontClose = () => {
    this.setState({ displayFontColorPicker: false })
  };

  onFormSubmit() {
    var data = {
      Name:this.state.promoName,
      PromoText:this.state.promoText,
      Active:false,
      BackgroundColor:this.state.background,
      FontColor:this.state.fontColor,
    }
    AddPromotion(data).then(() => {this.props.action()});
    this.setState({promoName:"",promoText:"", background:"",fontColor:""})
   }
   render() {
    const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }
    return (
        <Row>
<Button onClick={ this.handleClick } style={{marginLeft:10}}>Pick Background Color</Button>
        { this.state.displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleClose }/>
          <SketchPicker
        color={ this.state.background }
        onChangeComplete={ this.handleChangeComplete }
      /> 
       </div> : null }

       <Button onClick={ this.handleFontClick } style={{marginLeft:10}}>Pick Font Color</Button>
        { this.state.displayFontColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleFontClose }/>
          <SketchPicker
        color={ this.state.fontColor }
        onChangeComplete={ this.handleFontChangeComplete }
      /> 
       </div> : null }
           <Form inline>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="promoName" className="mr-sm-2">Name Of Promotion</Label>
                <Input type="text" name="promoName" id="promoName" value={this.state.promoName} placeholder="Enter Text..." onChange={e => this.setState({ promoName: e.target.value })}/>
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="promoText" className="mr-sm-2">Details Of Promotion</Label>
                <Input type="text" name="promoText" id="promoText" value={this.state.promoText} placeholder="Enter Text..." onChange={e => this.setState({ promoText: e.target.value })}/>
              </FormGroup>
              

              <FormGroup>
                <Label for="backgroundColor" className="mr-sm-2">Background and Font Color:</Label>
                <Input type="text" name="promoBackground" id="promoBackground" style={{backgroundColor:this.state.background, color:this.state.fontColor}} defaultValue="This is an example!"></Input>
              </FormGroup> 
            <Button type="button" onClick={(event) => {this.onFormSubmit(); }} style={{marginLeft:10}}>Submit</Button>
          </Form>
          </Row>
    )
   }
}