import { addDoc, collection, query, getDocs, where, updateDoc, doc, deleteDoc } from "firebase/firestore";
import {db} from "./firebase";

export async function GetActivePromotions(){
    const promotionsRef = collection(db, "promotions");
    const q = await query(promotionsRef, where('Active', '==', true))
    const querySnapshot = await getDocs(q);
    var promotionsArray = []
    
    querySnapshot.forEach((doc) => {
        var promotion = { id: doc.id, ...doc.data() }
        promotionsArray.push(promotion)
      });
      console.log(promotionsArray)
      return promotionsArray;
}

export async function GetPromotions(){
  const querySnapshot = await getDocs(collection(db, "promotions"));
  var promotionsArray = []
  
  querySnapshot.forEach((doc) => {
      var promotion = { id: doc.id, ...doc.data() }
      promotionsArray.push(promotion)
    });
    return promotionsArray;
}

export async function UpdatePromotionStatus(id, status){
    console.log(id, status)
  const docRef = doc(db, "promotions", id);
  await updateDoc(docRef, {
    Active: status
  });
}

export async function UpdatePromotionInfo(id, name, promoText, active, backgroundColor, fontColor){
  const docRef = doc(db,"promotions", id);
  await updateDoc(docRef,{
    Name: name,
    PromoText:promoText,
    Active: active,
    BackgroundColor: backgroundColor,
    FontColor: fontColor
  })
}

export async function DeletePromotion(id){
  await deleteDoc(doc(db, "promotions", id));
}

export async function AddPromotion(data){
    await addDoc(collection(db, "promotions"), {
        Name: data.Name,
        PromoText:data.PromoText,
        Active: data.Active,
        BackgroundColor: data.BackgroundColor,
        FontColor: data.FontColor
      }).then(() =>{
      });
}
