    export function GetDateFromTimeStamp(date){
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
        let jsDate = new Date(date.seconds * 1000);
    
        let dayOfWeek = daysOfWeek[jsDate.getDay()];
        let dayOfMonth = jsDate.getDate();
        let month = monthsOfYear[jsDate.getMonth()];
        let year = jsDate.getFullYear();
    
        // Add proper ordinal suffix
        let suffix = "th";
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            suffix = "st";
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            suffix = "nd";
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            suffix = "rd";
        }
    
        return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`
    }