import React, { Component } from 'react';
import Calendar from 'react-calendar';
import {
    Container,
    Row,
    Col,
    Button, Form, FormGroup, Label, Input,
  } from 'reactstrap';
  import {postData} from '../scripts/PostData';
import { AddBlockedDate } from '../services/dates-service';

export default class DateManagerEditor extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            dates:this.props.dates,
            date:new Date(),
            disabledDates:[],
            comments:""
        }
    }

    componentDidMount(){
      if(typeof this.props.disabledDates != 'undefined')
    {
        var disabledDatesArray = []
        for(var i = 0; i < this.props.disabledDates.length; i++){
            disabledDatesArray.push({"date": new Date(this.props.disabledDates[i].date.seconds * 1000)})
        }
        console.log(disabledDatesArray)
        this.setState({ disabledDates:disabledDatesArray });  
    }
      
    }

    onChange = date => this.setState({ date })

    convertDate(date) {
        var dateTest = new Date(date);
        return (
            dateTest.toLocaleString('en-GB').substring(0, dateTest.toLocaleString('en-GB').indexOf(','))
        )
    }

    displayDate(date){
        if(date.length > 0){
            return(
                <div>date</div>
            )
        }
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        if(typeof nextProps.disabledDates != 'undefined')
            {
                var disabledDatesArray = []
                for(var i = 0; i < nextProps.disabledDates.length; i++){
                    disabledDatesArray.push({"date": new Date(nextProps.disabledDates[i].date.seconds * 1000)})
                }
                console.log(disabledDatesArray)
                this.setState({ disabledDates:disabledDatesArray });  
            }

      }

    async onFormSubmit() {
        var data = {
          Date:new Date(this.state.date).getTime() + 6000000,
          Status:true,
          Comments:this.state.comments,
        }
        await AddBlockedDate(data).then(() => {
            this.setState({date:"", comments:""});
        })
       }

       handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
          [name]: value,
        });
      }



       render() {
        
        
        return (
            <Container>
                <Row>
                    <Col>
                    Select a date to disable
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Calendar
                        minDate={new Date()}
    tileDisabled={({date, view }) => this.state.disabledDates.some(disabledDate => date.getDate() === disabledDate.date.getDate() && date.getMonth() === disabledDate.date.getMonth() && date.getFullYear() === disabledDate.date.getFullYear())
}
                            onChange={this.onChange}
                            value={this.state.date}
                        />
                    </Col>
                    <Col>
                        <Form >
                        <FormGroup>
                  <Label for="fullName">Date</Label>
                  <Input type="text" value={this.convertDate(this.state.date)} name="date" id="date" placeholder="Please select a date from the calendar" disabled  />
                </FormGroup>
                <FormGroup>
                    <Input type="textarea" value={this.state.comments} onChange={(e) => {this.handleChange(e)}} name="comments" id="comments" style={{height:200}} placeholder="Please enter a reason for blocking the date"/>
                </FormGroup>
                <Button type="button" onClick={(event) => {this.onFormSubmit(); this.props.action()}}
                >Block Date</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

