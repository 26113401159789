import { addDoc, collection, query, getDocs, orderBy, limit, where, deleteDoc, doc } from "firebase/firestore";
import {db} from "./firebase";

export async function GetBlockedDates(){
    const bookedDatesRef = collection(db, "blockedDates");
    const q = await query(bookedDatesRef, where('date', '>', getYesterdayTimestamp()))
    const querySnapshot = await getDocs(q);
    var blockedDatesArray = []
    
    querySnapshot.forEach((doc) => {
        console.log(doc.id, ' => ', doc.data());
        var blockedDate = { id: doc.id, ...doc.data() }
        blockedDatesArray.push(blockedDate)
      });
      console.log(blockedDatesArray)
      return blockedDatesArray;
}

export async function GetBookedDates(){
const bookedDatesRef = collection(db, "bookedDates");
const q = await query(bookedDatesRef, where('Date', '>', getYesterdayTimestamp()))
const querySnapshot = await getDocs(q);
var bookedDatesArray = []

querySnapshot.forEach((doc) => {
  var bookedDate = { id: doc.id, ...doc.data() }
    bookedDatesArray.push(bookedDate)
  });
  return bookedDatesArray;
}

export async function GetHistoricBookings(){
  const bookedDatesRef = collection(db, "bookedDates");
const q = await query(bookedDatesRef, where('Date', '<', getTodayTimeStamp()))
const querySnapshot = await getDocs(q);
var bookedDatesArray = []

querySnapshot.forEach((doc) => {
  var bookedDate = { id: doc.id, ...doc.data() }
    bookedDatesArray.push(bookedDate)
  });
  return bookedDatesArray;
}

export async function AddBookedDate(data){
    const bookedDatesRef = collection (db,"bookedDates");
    const q = await query(bookedDatesRef, orderBy("BookingId", "desc"), limit(1))
    const querySnapshot = await getDocs(q);
    var latestBookingId
    querySnapshot.forEach((doc) => {
        latestBookingId = incrementBookingID(doc.data().BookingId)
      })
    await addDoc(collection(db, "bookedDates"), {
        BookingId: latestBookingId,
        Comments: data.Comments,
        Booked: data.Booked,
        Date: new Date(data.Date),
        Email: data.Email,
        fullName: data.fullName,
        location: data.location,
        Package:data.Package,
        Status: true,
        Telephone: data.Telephone
      }).then(() =>{
        
      });
}

export async function AddBlockedDate(data){
  console.log(data)
    await addDoc(collection(db, "blockedDates"), {
        comments: data.Comments,
        date: new Date(data.Date),
        status: true
      });
}

export async function DeleteBlockedDate(id){
  await deleteDoc(doc(db, "blockedDates", id));
}

function getYesterdayTimestamp() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0); // Set to the start of the day
    return yesterday;
  };

  function getTodayTimeStamp() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate());
    yesterday.setHours(0, 0, 0, 0); // Set to the start of the day
    return yesterday;
  };

function incrementBookingID(bookingID) {
    console.log(bookingID);
    
    // Extract the numeric part from the booking ID
    let match = bookingID.match(/\d+/);
    
    if (!match) {
        match = "0000001";
    }
    
    let numericPart = parseInt(match[0], 10);
    
    // Increment the numeric part by 1
    numericPart += 1;
    
    // Convert the incremented number back to a string
    let incrementedPart = numericPart.toString().padStart(match[0].length, '0');
    
    // Replace the old numeric part with the new incremented value
    let newBookingID = bookingID.replace(/\d+/, incrementedPart);
    
    return newBookingID
}
