import React, { Component } from 'react';
import {Row, Col, Button} from 'reactstrap';
import {postData} from '../scripts/PostData';
import { db } from '../services/firebase';
import { doc, deleteDoc } from "firebase/firestore";
import { DeleteBlockedDate } from '../services/dates-service';
export default class DateManagerTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dates:[],
        }
    }
    isActive = (status) => {
        if (status) {
            return "Blocked"
        }
        else {
            return "Inactive"
        }
    };
    componentWillMount(){
        console.log(this.props.dates)
        this.setState({dates:this.props.dates})

    }
    async getDates(){
        const that = this;
        await fetch("finesse/GetBlockedDates.php")
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (jsonStr) {
            that.setState({ dates: jsonStr}, function () { });
          }).catch(error => { console.log('request failed', error); });
      }

    componentWillReceiveProps(nextprops){
       this.setState({dates:nextprops.dates});
    }

    convertDate(date) {
        console.log(date)
        var d = new Date();
        var b = new Date(date.date.seconds * 1000).toLocaleString('en-GB');
        
        d.setTime((date*1000));
        return (
            b.substring(0, b.toLocaleString('en-GB').indexOf(','))
        )
    }

    async DeleteDocument(id) {
        console.log(id)

    }

    render() {
        return (
            <Row>
                <Col>
                    <table id="dateTable" style={{ fontSize: 15 }}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Blocked</th>
                                <th>Comments/Reasons</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dates.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{this.convertDate(data)}</td>
                                        <td>{this.isActive(data.status)}</td>
                                        <td>{data.comments}</td>
                                        <td><Button onClick={(event) => {DeleteBlockedDate(data.id); this.props.action()}}>Delete</Button>
                                             </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <br />
                    <br />
                </Col>
            </Row>
        )
    }
}